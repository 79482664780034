import {AmenitySection} from 'src/types/amenities'

export const familyFeatures: AmenitySection = {
  title: 'family_features',
  features: [
    {
      icon: 'babyBath.svg',
      label: 'baby_bath',
      section: 'family_features',
    },
    {
      icon: 'babyCrib.svg',
      label: 'baby_crib',
      section: 'family_features',
    },
    {
      icon: 'bathtub.svg',
      label: 'bathtub',
      section: 'family_features',
    },
    {
      icon: 'boardGames.svg',
      label: 'board_games',
      section: 'family_features',
    },
    {
      icon: 'foosballTable.svg',
      label: 'foosball_table',
      section: 'family_features',
    },
    {
      icon: 'gameConsole.svg',
      label: 'game_console',
      section: 'family_features',
    },
    {
      icon: 'highChair.svg',
      label: 'high_chair',
      section: 'family_features',
    },
    {
      icon: 'pingPong.svg',
      label: 'ping_pong_table',
      section: 'family_features',
      width: 85,
    },
    {
      icon: 'poolTable.svg',
      label: 'pool_table',
      section: 'family_features',
    },
    {
      icon: 'changingTable.svg',
      label: 'changing_table',
      section: 'family_features',
      width: 85,
    },
  ],
}
