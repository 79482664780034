import {AmenitySection} from 'src/types/amenities'

export const accessibility: AmenitySection = {
  title: 'accessibility',
  features: [
    {
      icon: 'elevator.svg',
      label: 'elevator_in_the_building',
      section: 'accessibility',
      width: 75,
    },
    {
      icon: 'wheelChair.svg',
      label: 'wheelchair_accessible',
      section: 'accessibility',
    },
    {
      icon: 'singleLevelHome.svg',
      label: 'single_level_home',
      section: 'accessibility',
    },
    {
      icon: 'privateEntrance.svg',
      label: 'private_entrance',
      section: 'accessibility',
    },
  ],
}
