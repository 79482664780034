import {AmenitySection} from 'src/types/amenities'

export const exclusion: AmenitySection = {
  title: 'exclusion',
  features: [
    {
      icon: 'propaneExcluded.svg',
      label: 'propane_excluded',
      section: 'exclusion',
    },
    {
      icon: 'fireWoodExcluded.svg',
      label: 'firewood_excluded',
      section: 'exclusion',
    },
  ],
}
