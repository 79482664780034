import {AmenitySection} from 'src/types/amenities'

export const coffee: AmenitySection = {
  title: 'coffee',
  features: [
    {
      icon: 'teaIncluded.svg',
      label: 'coffee_tea_included',
      section: 'coffee',
    },
    {
      icon: 'espressoMachine.svg',
      label: 'espresso_machine',
      section: 'coffee',
    },
    {
      icon: 'filterCoffeeMaker.svg',
      label: 'filter_coffee_maker',
      section: 'coffee',
    },
    {
      icon: 'italianCoffeeMaker.svg',
      label: 'italian_coffee_maker',
      section: 'coffee',
    },
    {
      icon: 'frenchPressCoffeeMaker.svg',
      label: 'french_press_coffee_maker',
      section: 'coffee',
    },
    {
      icon: 'podCoffeeMaker.svg',
      label: 'pod_coffee_maker',
      section: 'coffee',
    },
  ],
}
