import {AmenityFeatureProps} from 'src/types/amenities'

export default function AmenityIcon(props: {amenity: AmenityFeatureProps}) {
  const {amenity} = props

  const style: any = amenity.width
    ? {
        width: `${amenity.width}%`,
        height: `${amenity.width}%`,
      }
    : {
        width: `100%`,
        height: `100%`,
      }

  return (
    <div className="w-8 min-w-8 h-8 flex flex-row justify-start items-center">
      <img
        className="object-contain"
        loading="lazy"
        draggable={false}
        alt={amenity.label}
        src={`/images/amenities/${amenity.section}/${amenity.icon}`}
        style={style}
      />
    </div>
  )
}
