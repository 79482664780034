import {AmenitySection} from 'src/types/amenities'

export const kitchen: AmenitySection = {
  title: 'kitchen',
  features: [
    {
      icon: 'cookingBasics.svg',
      label: 'cooking_basics',
      section: 'kitchen',
    },
    {
      icon: 'dishesAndSilverware.svg',
      label: 'dishes_and_silverware',
      section: 'kitchen',
    },
    {
      icon: 'dishWasher.svg',
      label: 'dishwasher',
      section: 'kitchen',
    },
    {
      icon: 'microWave.svg',
      label: 'microwave',
      section: 'kitchen',
    },
    {
      icon: 'oven.svg',
      label: 'oven',
      section: 'kitchen',
    },
    {
      icon: 'potsAndPans.svg',
      label: 'pots_and_pans',
      section: 'kitchen',
    },
    {
      icon: 'refrigerator.svg',
      label: 'refrigerator',
      section: 'kitchen',
    },
    {
      icon: 'stove.svg',
      label: 'stove',
      section: 'kitchen',
    },
    {
      icon: 'electricRaclette.svg',
      label: 'Electric raclette pan',
      section: 'kitchen',
      width: 85,
    },
    {
      icon: 'gasRaclette.svg',
      label: 'Gas raclette pan',
      section: 'kitchen',
      width: 85,
    },
    {
      icon: 'gasFondueStove.svg',
      label: 'Gas fondue stove',
      section: 'kitchen',
    },
    {
      icon: 'electricFondueStove.svg',
      label: 'Electric fondue stove',
      section: 'kitchen',
    },
  ],
}
