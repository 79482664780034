import {AmenitySection} from 'src/types/amenities'

export const outdoorFeatures: AmenitySection = {
  title: 'outdoor_features',
  features: [
    {
      icon: 'accessToWater.svg',
      label: 'access_to_water',
      section: 'outdoor_features',
    },
    {
      icon: 'accessToGarage.svg',
      label: 'access_to_a_garage',
      section: 'outdoor_features',
    },
    {
      icon: 'backyard.svg',
      label: 'backyard',
      section: 'outdoor_features',
    },
    {
      icon: 'beachChairs.svg',
      label: 'beach_chairs',
      section: 'outdoor_features',
    },
    {
      icon: 'beachFront.svg',
      label: 'beachfront',
      section: 'outdoor_features',
    },
    {
      icon: 'boatLandingStage.svg',
      label: 'boat_landing_stage',
      section: 'outdoor_features',
    },
    {
      icon: 'dock.svg',
      label: 'dock',
      section: 'outdoor_features',
    },
    {
      icon: 'firePit.svg',
      label: 'fire_pit',
      section: 'outdoor_features',
    },
    {
      icon: 'hamak.svg',
      label: 'hamak',
      section: 'outdoor_features',
    },
    {
      icon: 'lifeJackets.svg',
      label: 'life_jackets',
      section: 'outdoor_features',
    },
    {
      icon: 'electricBoat.svg',
      label: 'navigable_lake_by_electric_boat',
      section: 'outdoor_features',
    },
    {
      icon: 'motorBoat.svg',
      label: 'navigable_lake_by_motor_boat',
      section: 'outdoor_features',
    },
    {
      icon: 'pool.svg',
      label: 'pool',
      section: 'outdoor_features',
    },
    {
      icon: 'propaneIncluded.svg',
      label: 'propane_included',
      section: 'outdoor_features',
    },
    {
      icon: 'sandyBeach.svg',
      label: 'sandy_beach',
      section: 'outdoor_features',
    },
    {
      icon: 'sauna.svg',
      label: 'sauna',
      section: 'outdoor_features',
      width: 85,
    },
    {
      icon: 'spa.svg',
      label: 'spa',
      section: 'outdoor_features',
    },
    {
      icon: 'view.svg',
      label: 'view',
      section: 'outdoor_features',
    },
    {
      icon: 'volleyballNet.svg',
      label: 'volleyball_net',
      section: 'outdoor_features',
    },
    {
      icon: 'bbqGrill.svg',
      label: 'bbq_grill',
      section: 'outdoor_features',
    },
    {
      icon: 'byARiver.svg',
      label: 'by_a_river',
      section: 'outdoor_features',
    },
    {
      icon: 'lakeFront.svg',
      label: 'lakefront',
      section: 'outdoor_features',
    },
    {
      icon: 'balcony.svg',
      label: 'patio_/_balcony',
      section: 'outdoor_features',
      width: 85,
    },
    {
      icon: 'skiInSkiOut.svg',
      label: 'ski-in/ski-out',
      section: 'outdoor_features',
    },
    {
      icon: 'waterfront.svg',
      label: 'waterfront',
      section: 'outdoor_features',
    },
  ],
}
