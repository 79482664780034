import {AmenitySection} from 'src/types/amenities'

export const services: AmenitySection = {
  title: 'services',
  features: [
    {
      icon: 'bachelorParty.svg',
      label: 'bachelor(ette)_party',
      section: 'services',
    },
    {
      icon: 'birthdayParty.svg',
      label: 'birthday_party',
      section: 'services',
    },
    {
      icon: 'conferenceRoom.svg',
      label: 'conference_room',
      section: 'services',
    },
    {
      icon: 'corporateMeeting.svg',
      label: 'corporate_meeting',
      section: 'services',
    },
    {
      icon: 'gymAccess.svg',
      label: 'gym_access',
      section: 'services',
    },
    {
      icon: 'oneNightRental.svg',
      label: 'one_night_rental',
      section: 'services',
    },
    {
      icon: 'outdoorElectricalOutlet.svg',
      label: 'outdoor_electrical_outlet',
      section: 'services',
    },
    {
      icon: 'weddingReception.svg',
      label: 'wedding_reception',
      section: 'services',
    },
    {
      icon: 'evCharger.svg',
      label: 'ev_charger',
      section: 'services',
    },
    {
      icon: 'petFriendly.svg',
      label: 'pet_friendly',
      section: 'services',
      width: 75,
    },
    {
      icon: 'freeParking.svg',
      label: 'free_parking',
      section: 'services',
      width: 75,
    },
  ],
}
