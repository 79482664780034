import {AmenitySection} from 'src/types/amenities'

export const indoorFeatures: AmenitySection = {
  title: 'indoor_features',
  features: [
    {
      icon: 'airConditioning.svg',
      label: 'air_conditioning',
      section: 'indoor_features',
    },
    {
      icon: 'bedLinens.svg',
      label: 'bed_linens',
      section: 'indoor_features',
    },
    {
      icon: 'cableTV.svg',
      label: 'cable_tv',
      section: 'indoor_features',
    },
    {
      icon: 'computerWorkspace.svg',
      label: 'computer_workspace',
      section: 'indoor_features',
    },
    {
      icon: 'dryingMachine.svg',
      label: 'drying_machine',
      section: 'indoor_features',
    },
    {
      icon: 'dvd.svg',
      label: 'dvd_blue_ray',
      section: 'indoor_features',
    },
    {
      icon: 'extraPillowAndBlanket.svg',
      label: 'extra_pillows_and_blankets',
      section: 'indoor_features',
    },
    {
      icon: 'fireWood.svg',
      label: 'firewood_included',
      section: 'indoor_features',
    },
    {
      icon: 'hairDryer.svg',
      label: 'hair_dryer',
      section: 'indoor_features',
    },
    {
      icon: 'hangers.svg',
      label: 'hangers_in_closet',
      section: 'indoor_features',
    },
    {
      icon: 'heatedFloors.svg',
      label: 'heated_floors',
      section: 'indoor_features',
    },
    {
      icon: 'heating.svg',
      label: 'heating',
      section: 'indoor_features',
    },
    {
      icon: 'hotWater.svg',
      label: 'hot_water',
      section: 'indoor_features',
    },
    {
      icon: 'indoorFireplace.svg',
      label: 'indoor_fireplace',
      section: 'indoor_features',
      width: 85,
    },
    {
      icon: 'iron.svg',
      label: 'iron',
      section: 'indoor_features',
    },
    {
      icon: 'kitchen.svg',
      label: 'kitchen',
      section: 'indoor_features',
    },
    {
      icon: 'potableWater.svg',
      label: 'potable_water',
      section: 'indoor_features',
    },
    {
      icon: 'shampoo.svg',
      label: 'shampoo_&_soap',
      section: 'indoor_features',
    },
    {
      icon: 'toiletPaper.svg',
      label: 'toilet_paper',
      section: 'indoor_features',
    },
    {
      icon: 'towels.svg',
      label: 'towels',
      section: 'indoor_features',
    },
    {
      icon: 'tv.svg',
      label: 'tv',
      section: 'indoor_features',
    },
    {
      icon: 'washingMachine.svg',
      label: 'washing_machine',
      section: 'indoor_features',
    },
    {
      icon: 'wifi.svg',
      label: 'wifi',
      section: 'indoor_features',
    },
  ],
}
