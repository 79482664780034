import {AmenitySection} from 'src/types/amenities'

export const sports: AmenitySection = {
  title: 'sports',
  features: [
    {
      icon: 'alpineSkiing.svg',
      label: 'alpine_skiing',
      section: 'sports',
    },
    {
      icon: 'atvTrail.svg',
      label: 'atv_trail',
      section: 'sports',
    },
    {
      icon: 'basketball.svg',
      label: 'basketball',
      section: 'sports',
    },
    {
      icon: 'boating.svg',
      label: 'boating',
      section: 'sports',
    },
    {
      icon: 'canoeing.svg',
      label: 'canoeing',
      section: 'sports',
    },
    {
      icon: 'crossCountrySkiing.svg',
      label: 'cross-country_skiing',
      section: 'sports',
    },
    {
      icon: 'deepSeaFishing.svg',
      label: 'deep_sea_fishing',
      section: 'sports',
    },
    {
      icon: 'fishing.svg',
      label: 'fishing',
      section: 'sports',
    },
    {
      icon: 'golf.svg',
      label: 'golf',
      section: 'sports',
      width: 75,
    },
    {
      icon: 'hiking.svg',
      label: 'hiking',
      section: 'sports',
    },
    {
      icon: 'hockey.svg',
      label: 'hockey',
      section: 'sports',
    },
    {
      icon: 'horsebackRiding.svg',
      label: 'horseback_riding',
      section: 'sports',
    },
    {
      icon: 'iceClimbing.svg',
      label: 'ice_climbing',
      section: 'sports',
    },
    {
      icon: 'jetSkiing.svg',
      label: 'jet_skiing',
      section: 'sports',
    },
    {
      icon: 'kayaking.svg',
      label: 'kayaking',
      section: 'sports',
      width: 75,
    },
    {
      icon: 'mountainBiking.svg',
      label: 'mountain_biking',
      section: 'sports',
      width: 85,
    },
    {
      icon: 'paddleboard.svg',
      label: 'paddleboard',
      section: 'sports',
      width: 90,
    },
    {
      icon: 'roadBiking.svg',
      label: 'road_biking',
      section: 'sports',
    },
    {
      icon: 'rockClimbing.svg',
      label: 'rock_climbing',
      section: 'sports',
    },
    {
      icon: 'rollerBlading.svg',
      label: 'rollerblading',
      section: 'sports',
    },
    {
      icon: 'running.svg',
      label: 'running',
      section: 'sports',
    },
    {
      icon: 'sailboard.svg',
      label: 'sailboard',
      section: 'sports',
    },
    {
      icon: 'sailing.svg',
      label: 'sailing',
      section: 'sports',
    },
    {
      icon: 'scubaDiving.svg',
      label: 'scuba_diving',
      section: 'sports',
    },
    {
      icon: 'skating.svg',
      label: 'skating',
      section: 'sports',
    },
    {
      icon: 'skyDiving.svg',
      label: 'skydiving',
      section: 'sports',
    },
    {
      icon: 'swimming.svg',
      label: 'swimming',
      section: 'sports',
    },
    {
      icon: 'tennis.svg',
      label: 'tennis',
      section: 'sports',
    },
    {
      icon: 'snorkeling.svg',
      label: 'snorkeling',
      section: 'sports',
      width: 85,
    },
    {
      icon: 'snowboarding.svg',
      label: 'snowboarding',
      section: 'sports',
    },
    {
      icon: 'snowmobileTrail.svg',
      label: 'snowmobile_trail',
      section: 'sports',
    },
    {
      icon: 'snowshoeing.svg',
      label: 'snowshoeing',
      section: 'sports',
    },
  ],
}
